import React, { 
  useEffect, useState 
} from 'react'
import { 
  // Router, 
  Routes, Route , Navigate} from 'react-router-dom'
import Main from '../components/layouts/public/main/Main';
import {
  // Frontend
  Home,
  Mortgages,
  Loans,
  CreditCards,
  Blogs,
  BlogsCategory,
  BlogDetail,
  Investments,
  Banking,
  Insurance,
  Contact,
  MoneyTransfers,
  CreditScore,
  Cards,
  Eligibility,
  Result,
  Result2,
  AgentsDirectory,
  RateshopMortgage,
  RateshopMortgageLocation,
  RateshopMortgageLocationCity,
  ApplyNow,
  ApplyNowEmbed,

  ApplyNowWidget,
  CommercialMortgage,
  JoinUs,
  Packages,
  // Sweaptake
  Sweaptake,
  // Not Found
  NotFound,
  // Application 
  ApplicationPurchase,
  ApplicationAddresses,
  ApplicationApplicant,
  ApplicationConcent,
  ApplicationDownpayment,
  ApplicationIncome,
  ApplicationLiabilities,
  ApplicationProperties,
  SubjectProperties,
  Sitemap,
  JoinUsDemo,
  Webmail,
  PrivacyPolicy
  

} from '../pages/public/'
import AgentsDirectoryDetails from '../pages/public/frontend/agents-directory-detail/AgentsDirectoryDetails';
import { AuthRoute, GuestRoute } from '../routes';
// import "./AppVariables.scss";
// import axios from 'axios';
import "./App.scss";
import SweaptakeHead from './SweaptakeHead';
import ApplyHead from './ApplyHead';
import ApplyHeadWidget from './ApplyNowWidget';
import NotFoundHead from './notFoundHead';
import JoinHead from './joinHead';
import JoinHeadPackages from './joinHeadpackages';
// import { useLocation } from "react-router-dom";

// application
import ApplicationHead from './applicationHead'
import { useCookies } from 'react-cookie';
import NewBlogDetail from '../pages/public/frontend/new-blog-detail/New-blog-detail';
import NewBlogDetail2 from '../pages/public/frontend/new-blog-detail-2/New-blog-detail';
import Locations from '../pages/public/frontend/locations/Locations';
import LocationDetail from '../pages/public/frontend/location-detail/LocationDetail';
import MortgageAgent from '../pages/public/frontend/mortgage-agent/MortgageAgent';
import MortgagePaymentCalculator from '../pages/public/frontend/mortgage-payment-calculator/MortgagePaymentCalculator';
import AffordabilityCalculator from '../pages/public/frontend/affordability-calculator/AffordabilityCalculator';
import LandTransferTaxCalculator from '../pages/public/frontend/land-transfer-tax-calculator/LandTransferTaxCalculator';
import CmhcInsuranceCalculator from '../pages/public/frontend/cmhc-insurance-calculator/CmhcInsuranceCalculator';  
import Closingcostcalculator from '../pages/public/frontend/closing-cost-calculator/Closingcostcalculator';
import Rss from '../pages/public/frontend/rss/Rss';
import DynamicSitemap from '../../src/sitemap';
import ApplyHeadEmbed from './ApplyHeadEmbed';
import NewBlogDetail3 from '../pages/public/frontend/new-blog-detail-3/New-blog-detail';

const App = () => {

  return (
    <>
      <Routes>
      
     

        <Route name="Main" path="/" element={<Main />}> 
          <Route
            name="home-page"
            index
            element={<Home />}
          /> 
           <Route
            name="privacy-policy"
            path="privacy-policy"
            element={<PrivacyPolicy />}
          /> 
       <Route
            name="webmail"
            path="webmail"
            element={<Webmail />}
          /> 
       
          <Route
            name="home-page"
            path={":slug"}
            element={<Home />}
          /> 
          {/* <Route
            name="mortgages-page"
            path={"mortgages/:slug"}
            element={<Mortgages />}
          />  */}
          {/* {agentslugs.forEach(element => 
                    <Route 
                    name="mortgages-page" 
                    path={`"mortgages/${element.Slug}"` }
                    element={<Mortgages/>} 
                   />)} */}
          {/* <Route
            name="mortgages-page"
            path="mortgages"
            element={<Mortgages />}
          /> */}
            <Route
            name="404-page"
            path="404"
            element={<NotFound />}
          />



          {/* <Route
            name="site-map"
            path="sitemap"
            element={<Sitemap />}
          /> */}
          {/* <Route
            name="loans-page"
            path="loans"
            element={<Loans />}
          /> */}
          <Route
            name="credit-cards-page"
            path="credit-cards"
            element={<CreditCards />}
          />
          <Route
            name="blogs-page"
            path="learning-center"
            element={<Blogs />}
          />

          
          <Route
            name="blogs-category-page"
            path="learning-center/category/:cname"
            element={<BlogsCategory />}
          />


          {/* <Route
            name="new-blog-detail"
            path="learning-center-detail"
            element={<NewBlogDetail />}
          /> */}
          <Route
            name="locations"
            path="locations"
            element={<Locations />}
          /> 
          {/* <Route
            name="location-detail"
            path="location-detail"
            element={<LocationDetail />}
          /> */}
          <Route
            name="mortgage-agent"
            path="mortgage-agent"
            element={<MortgageAgent />}
          />
          {/* <Route
            name="blog-detail-page"
            path="blog-detail"
            element={<BlogDetail />}
          /> */}
          
          <Route exact path="/learning-center/:post_slug" element={<BlogDetail />} />
          <Route exact path="/mortgage-lender/:post_slug" element={<NewBlogDetail />} />
          <Route exact path=":slug/mortgage-lender/:post_slug" element={<NewBlogDetail />} />
  
          <Route path="/:state/:city/:post_slug" element={<NewBlogDetail2 />} />
          
          <Route path="/investing-in-private-mortgage" element={<NewBlogDetail3 />} />
          <Route path="/investing-in-private-mortgage" element={<NewBlogDetail3 />} />
          <Route path="/bridge-loans" element={<NewBlogDetail3 />} />
          <Route path="/reverse-mortgage" element={<NewBlogDetail3 />} />
          <Route path="/power-of-sale-mortgage" element={<NewBlogDetail3 />} />
          <Route path="/rural-mortgage" element={<NewBlogDetail3 />} />
          <Route path="/renovation-mortgage" element={<NewBlogDetail3 />} />
          <Route path="/mortgage-arrears" element={<NewBlogDetail3 />} />
          <Route path="/cmhc-residential-mortgage" element={<NewBlogDetail3 />} />
          <Route path="/commercial-mortgage" element={<NewBlogDetail3 />} />
          <Route path="/home-equity-loan" element={<NewBlogDetail3 />} />
          <Route path="/debt-consolidation-mortgage" element={<NewBlogDetail3 />} /> 
          <Route path="/private-mortgage" element={<NewBlogDetail3 />} /> 

          <Route exact path="/contact/:loc_slug" element={<LocationDetail />} />

          <Route
            name="investments-page"
            path="investments"
            element={<Investments />}
          />
          {/* <Route
            name="banking-page"
            path="banking"
            element={<Banking />}
          /> */}
          <Route
            name="mortgage-payment-calculator"
            path="mortgage-payment-calculator"
            element={<MortgagePaymentCalculator />}
          />
          <Route
            name="mortgage-payment-calculator"
            path=":slug/mortgage-payment-calculator"
            element={<MortgagePaymentCalculator />}
          />
          <Route
            name="affordability-calculator"
            path="affordability-calculator"
            element={<AffordabilityCalculator />}
          />
          {/* <Route
            name="rss"
            path="rss"
            element={<Rss />}
          /> */}
          <Route
            name="affordability-calculator"
            path=":slug/affordability-calculator"
            element={<AffordabilityCalculator />}
          />
          <Route
            name="land-transfer-tax-calculator"
            path="land-transfer-tax-calculator"
            element={<LandTransferTaxCalculator />}
          />
           <Route
            name="land-transfer-tax-calculator"
            path=":slug/land-transfer-tax-calculator"
            element={<LandTransferTaxCalculator />}
          />
          <Route
            name="cmhc-insurance-calculator"
            path="cmhc-insurance-calculator"
            element={<CmhcInsuranceCalculator />}
          />
          <Route
            name="cmhc-insurance-calculator"
            path=":slug/cmhc-insurance-calculator"
            element={<CmhcInsuranceCalculator />}
          />
          <Route
            name="cost-closing-calculator"
            path="cost-closing-calculator"
            element={<Closingcostcalculator />}
          /> 
          <Route
            name="cost-closing-calculator"
            path=":slug/cost-closing-calculator"
            element={<Closingcostcalculator />}
          /> 
          <Route
            name="insurance-page"
            path="insurance"
            element={<Insurance />}
          />
          <Route
            name="contact-page"
            path="contact"
            element={<Locations />}
          />
          <Route
            name="contact-page"
            path=":slug/contact"
            element={<Locations />}
          />
          <Route
            name="money-transfers-page"
            path="money-transfers"
            element={<MoneyTransfers />}
          />

          {/* <Route
            name="credit-score-page"
            path="credit-score"
            element={<CreditScore />}
          />  */}

          <Route
            name="credit-score-page"
            path=":slug/credit-score"
            element={<CreditScore />}
          /> 
          <Route
            name="agent-directory-page"
            path="agent-directory"
            element={<AgentsDirectory />}
          /> 
          <Route
            name="agent-directory-detail-page"
            path="agent/:slug"
            element={<AgentsDirectoryDetails />}
          /> 
          <Route
            name="rateshop-mortgage-page"
            path="mortgage-rates"
            element={<RateshopMortgage />}
          /> 
          <Route
            name="rateshop-mortgage-page"
            path=":slug/mortgage-rates"
            element={<RateshopMortgage />}
          /> 
          <Route
            name="rateshop-mortgage-location-page"
            path="mortgage-rates/:currentLocation"
            element={<RateshopMortgageLocation />}
          /> 

          <Route
            name="rateshop-mortgage-location-page"
            path=":slug/mortgage-rates/:currentLocation"
            element={<RateshopMortgageLocation />}
          /> 
           <Route
            name="rateshop-mortgage-location-page"
            path="mortgage-rates/:currentLocation/:currentCity"
            element={<RateshopMortgageLocationCity />}
          /> 
          <Route
            name="rateshop-mortgage-location-page"
            path=":slug/mortgage-rates/:currentLocation/:currentCity"
            element={<RateshopMortgageLocationCity />}
          /> 
          <Route
            name="cards"
            path="cards"
            element={<Cards />}
          /> 
          <Route
            name="eligibiity"
            path="eligibiity"
            element={<Eligibility />}
          />
  
          <Route
            name="result"
            path="result"
            element={<Result />}
          />

          <Route
            name="result2"
            path="result2"
            element={<Result2 />}
          />

<Route
            name="CommercialMortgage"
            path="commercial-mortgage"
            element={<CommercialMortgage />}
          />




          {/* Auth-Routes */}
          <Route name="profile" path="profile" element={<AuthRoute></AuthRoute>} />

          {/* Guest-Routes */}
          <Route name="login" path="login" element={<GuestRoute></GuestRoute>} />
        </Route>

       
        <Route path="/" element={<ApplyHead />}>
          <Route
            name="sweepstake2023"
            path=':slug/sweepstake2023'
            element={<ApplyNow />}
          />
        </Route>
        {/* Apply Now */}
        <Route path="/" element={<ApplyHead />}>
          <Route
            name="apply"
            path='apply/:slug'
            element={<ApplyNow />}
          />
        </Route>
        <Route path="/" element={<ApplyHead />}>
          <Route
            name="apply"
            path='apply'
            element={<ApplyNow />}
          />
        </Route>

        <Route path="/" element={<ApplyHeadEmbed/>}>
          <Route
            name="apply-embed"
            path='web-embed'
            element={<ApplyNowEmbed />}
          />
        </Route>
        <Route path="/" element={<ApplyHead />}>
          <Route
            name="get-quote"
            path='get-quote/:slug'
            element={<ApplyNow />}
          />
        </Route>

        
        <Route path="/" element={<ApplyHead />}>
          <Route
            name="mortgage-broker"
            path='mortgage-broker/:slug'
            element={<ApplyNow />}
          />
        </Route>
        
        {/* <Route path='/' element={<NotFoundHead />}>
          <Route path='/404' element={<NotFound />} />
        </Route> */}

         <Route path="/" element={<ApplyHeadWidget />}>
          <Route
            name="apply-widget"
            path='apply-widget'
            element={<ApplyNowWidget />}
          />
          <Route
            name="develop-custom-sitemap"
            path="develop-custom-sitemap"
            element={<DynamicSitemap />}
          /> 
        </Route>
      


        {/* Application form ================================*/}
        {/* <Route path="/" element={<ApplicationHead />}>
          <Route
            name="application/purchase"
            path='application/purchase/:appId'
            element={<ApplicationPurchase />}
          />
        </Route>
        <Route path="/" element={<ApplicationHead />}>
          <Route
            name="application/address"
            path='application/address'
            element={<ApplicationAddresses />}
          />
        </Route>
        <Route path="/" element={<ApplicationHead />}>
          <Route
            name="application/applicant"
            path='application/applicant/:appId'
            element={<ApplicationApplicant />}
          />
        </Route>
        <Route path="/" element={<ApplicationHead />}>
          <Route
            name="application/consent"
            path='application/consent'
            element={<ApplicationConcent />}
          />
        </Route>
        <Route path="/" element={<ApplicationHead />}>
          <Route
            name="application/down-payment"
            path='application/down-payment'
            element={<ApplicationDownpayment />}
          />
        </Route>
        <Route path="/" element={<ApplicationHead />}>
          <Route
            name="application/income"
            path='application/income'
            element={<ApplicationIncome />}
          />
        </Route>
        <Route path="/" element={<ApplicationHead />}>
          <Route
            name="application/liabilities"
            path='application/liabilities'
            element={<ApplicationLiabilities />}
          />
        </Route>
        <Route path="/" element={<ApplicationHead />}>
          <Route
            name="application/properties"
            path='application/properties'
            element={<ApplicationProperties />}
          />
        </Route>
        <Route path="/" element={<ApplicationHead />}>
          <Route
            name="application/subject-properties"
            path='application/subject-properties'
            element={<SubjectProperties />}
          />
        </Route> */}

      {/* join now ================== */}
        <Route path="/" element={<JoinHead />}>
        <Route
            name="join-us"
            path="join-us"
            element={<JoinUsDemo />}
          />
          </Route>
        {/* <Route path="/" element={<JoinHead />}> */}
        {/* <Route
            name="join-us-demo"
            path="join-us-demo"
            element={<JoinUsDemo />}
          />
          </Route>  */}
        <Route path="/" element={<JoinHeadPackages />}>
        <Route
            name="join-us/packages"
            path="join-us/packages"
            element={<Packages />}
          />
          </Route>
      </Routes>

 
    </>
  );
}

export default App;
