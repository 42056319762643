import React, { useRef, useState } from 'react' 
import { Container } from "@mui/material"; 
import './closing-cost-calculator.scss';
import Helmet from 'react-helmet';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Link} from 'react-router-dom'

const Closingcostcalculator = function () { 
    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Closing Cost Calculator | Estimate Your Closing Costs | RateShop.ca</title>
          <meta name='description' content="Estimate your closing costs with RateShop.ca's Closing Cost Calculator. Understand all expenses involved in your home purchase and budget accordingly." />
          <meta name="keywords" content=" closing cost calculator, estimate closing costs, home purchase costs, closing expenses, closing cost estimation, RateShop, home buying costs, closing fee calculator, real estate closing costs, home closing costs, closing cost breakdown, closing cost planning, closing cost estimator, calculate closing costs, closing cost guide, closing cost tool, mortgage closing costs, purchase closing costs, closing cost prediction, closing cost calculator Canada"/>
        </Helmet>
            <section className="c-c-calc sec-padding">
                <Container> 
                <div className="cs-content cs-max-w pb-10">
                    <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link> 
                <Typography color="text.primary">Closing Cost Calculator </Typography>
            </Breadcrumbs> 
            </div>
                <iframe id="idIframe" src="https://ref.rateshop.ca/web/rs-closing-cost-calc.php" width="100%" height="1000" title="cmhc insurance calculator" ></iframe>
                    {/* <iframe src="https://calc.rateshop.ca/purchase.html" width="100%"    
                    height="1700px"
                    title="Mortgage Payment Calculator"></iframe>  */}
                </Container>
            </section>
        </>
    )
}

export default Closingcostcalculator;