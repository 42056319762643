
import React, { useEffect, Component } from "react";
import "./rateshop-mortgage-location.scss";
import "aos/dist/aos.css"; 
import { Button, Container, Grid } from "@mui/material";
// import Partner from '../../../../components/shared/partner/Partner';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { Markup } from 'react-render-markup';   
import FormControl from '@mui/material/FormControl'; 
import Select from '@mui/material/Select';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Slider from '@mui/material/Slider';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Helmet from 'react-helmet';import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Link , Navigate} from 'react-router-dom'
// import Menu from '@mui/material/Menu';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import image1 from '../../../../assets/public/images/mortgage/location.png';
// import image2 from '../../../../assets/public/images/mortgage/notification.png';
// import image3 from '../../../../assets/public/images/mortgage/hsbc.png';
// import image4 from '../../../../assets/public/images/mortgage/TD-Bank-logo.png';
// import image5 from '../../../../assets/public/images/mortgage/bdc.png';
// import image6 from '../../../../assets/public/images/mortgage/lb.png';
// import image7 from '../../../../assets/public/images/mortgage/nboc.png';
// import image8 from '../../../../assets/public/images/mortgage/lowest.png';
// import image9 from '../../../../assets/public/images/mortgage/calculator1.png';
// import image10 from '../../../../assets/public/images/mortgage/calculator2.png';
// import image11 from '../../../../assets/public/images/mortgage/calculator3.png';
// import image12 from '../../../../assets/public/images/mortgage/graph.png';
// import image13 from '../../../../assets/public/images/mortgage/mortgage-term.png';
// import image14 from '../../../../assets/public/images/mortgage/variable-rates.png';
// import image15 from '../../../../assets/public/images/mortgage/insured.png';
// import image16 from '../../../../assets/public/images/mortgage/property-use.png';
// import image17 from '../../../../assets/public/images/mortgage/credit-score.png';
import get500 from "../../../../assets/public/images/get-500.png";
import { NavLink, useNavigate } from "react-router-dom";
import Calculator from "../../../../components/shared/calculator/Calculator";
import PartnerLogo2 from "../../../../components/shared/partner-logo2/PartnerLogo";
// images
import homeIcon from '../../../../assets/public/images/home-icon-silhouette.png';
import bellIcon from '../../../../assets/public/images/bell.png'; 
import img1 from '../../../../assets/public/images/mortgage/Call-to-action-button-image.jpg';
import img2 from '../../../../assets/public/images/mortgage/Mortgage-broker.png'; 
import img3 from '../../../../assets/public/images/mortgage/Happy-man.png'; 
import img4 from '../../../../assets/public/images/mortgage/Ariel-view-image-1.png';  
import img5 from '../../../../assets/public/images/mortgage/rateshop-building.gif';  
import img6 from '../../../../assets/public/images/mortgage/rateshop - logo moving around rateshop logo - Gif.gif';  
import img7 from '../../../../assets/public/images/mortgage/Comp 1377.png';  
import img8 from '../../../../assets/public/images/mortgage/House-image-1.png';   
import whitelogo from '../../../../assets/public/images/white-logo-nw.svg';
import clientImg from '../../../../assets/public/images/agent-3.png';  
import purchaseIcon from '../../../../assets/public/images/Purchase-icon.svg';
import refinanceIcon from '../../../../assets/public/images/Refinance-icon.svg';
import switchIcon from '../../../../assets/public/images/Switch-icon.svg';
import img10 from '../../../../assets/public/images/mortgage/Happy-lady-using-mobile-phone.png'; 

import mLogo1 from '../../../../assets/public/images/mort-icon-1.png'; 
import mLogo2 from '../../../../assets/public/images/mort-icon-2.png'; 
import mLogo3 from '../../../../assets/public/images/mort-icon-3.png'; 
import mLogo4 from '../../../../assets/public/images/mort-icon-4.png'; 
import mLogo5 from '../../../../assets/public/images/mort-icon-5.png'; 

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useParams as params } from "react-router-dom";
import { details } from "../../../../config/mortgage-rates-details-province";
import { Line } from 'react-chartjs-2';
import { Oval } from 'react-loader-spinner';
import useGeoLocation from "react-ipgeolocation";
import Input from '@mui/joy/Input';
import logo from '../../../../assets/public/images/webp/rateshop-logo.webp';
import MortgageRate from "../../../../components/shared/mortgage-rate/MortgageRate";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export const options = {
    responsive: true,
    updateMode: 'resize',
    tension: 0.26,
    // aspectRatio: 1/2,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: "Canada's Historical Mortgage Rates",
            font: {
                size: 30
            }
        },
        subtitle: {
            display: true,
            text: "Source: Bank of Canada Prime Rate History"
        },
        tooltip: {
            callbacks: {
                label: (item) =>
                    `${item.dataset.label}: ${item.formattedValue} %`,
            },
        },
    },
    scales: {
        x: {
            grid: {
                display: true
            }
        },
        y: {
            grid: {
                display: true
            },
            ticks: {
                callback: function (value, index, ticks) {
                    return value + '%';
                }
            }
        }
    }
};

const labels = ['1975', '1977', '1979', '1981', '1983', '1985', '1987', '1989', '1991', '1993', '1995', '1997', '1999', '2001', '2003', '2005', '2007', '2009', '2011', '2013', '2015', '2017', '2019', '2021', '2023'];

export const data = { 
    labels,
    datasets: [
        {
            label: 'Conventional mortgage - 1-year',
            data: [
                '8.70', '7.95', '11.70', '19.20', '10.70', '10.20', '9.20', '13.20', '9.45', '5.70', '7.95', '4.45', '5.95', '5.95', '4.45', '3.95', '5.70', '1.95', '2.70', '2.70', '2.55', '2.40', '3.65', '2.15', '6.28'],
            borderColor: 'rgb(0, 0, 0)',
            backgroundColor: 'rgba(0, 0, 0)',
            unitSuffix: "%",
        },
        {
            label: 'Conventional mortgage - 5-year',
            data: [
                '8.70', '7.65', '11.50', '19.60', '10.90', '10.10', '9.10', '13.50', '9.15', '5.90', '7.65', '4.25', '5.85', '5.75', '4.35', '3.65', '5.60', '1.85', '2.60', '2.80', '2.65', '2.50', '3.75', '2.25', '6.88'],
            borderColor: 'rgb(255, 0, 0)',
            backgroundColor: 'rgba(255, 0, 0)',
        },
        {
            label: 'Conventional mortgage - 3-year',
            data: [
                '8.70', '7.65', '11.50', '17.60', '9.90', '9.10', '8.10', '13.20', '9.05', '5.60', '8.65', '5.25', '8.85', '5.05', '4.95', '3.15', '5.10', '2.85', '3.60', '3.80', '4.65', '2.90', '3.15', '2.95', '8.88'],
            borderColor: 'rgba(18,0,115)',
            backgroundColor: 'rgba(18,0,115)',
        },
        {
            label: 'Prime rate',
            data: [
                '11.50', '10.25', '11.25', '18.13', '13.50', '11.75', '11.25', '12.00', '11.25', '8.85', '8.95', '7.00', '7.50', '7.50', '6.45', '6.05', '7.19', '5.79', '5.39', '5.24', '4.64', '4.64', '5.34', '4.79', '6.49'],
            borderColor: 'rgba(244,187,40)',
            backgroundColor: 'rgba(244,187,40)',
        }
    ],
};

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const RateshopMortgage = () => {
    const [expanded, setExpanded] = React.useState('panel1');
    const { slug } = params();
    const [agentSlug, setAgentSlug] = React.useState(slug);
    const [proFiltered, setProFiltered] = React.useState([]);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const [insuredThreeYearFixed, setInsuredThreeYearFixed] = React.useState([]);
    const [insuredFourYearFixed, setInsuredFourYearFixed] = React.useState([]);
    const [insuredFiveYearFixed, setInsuredFiveYearFixed] = React.useState([]);
    const [insuredFiveYearVariable, setInsuredFiveYearVariable] = React.useState([]);
    const [insuredOneYearFixed, setInsuredOneYearVariable] = React.useState([]);
    const [insuredTwoYearFixed, setInsuredTwoYearVariable] = React.useState([]);

    const [buyDownLender, setBuyDownLender] = React.useState([]);
    const [buyDownTheirRates, setBuyDownTheirRates] = React.useState([]);
    const [buyDownOurRates, setBuyDownOurRates] = React.useState([]);
    const [buyDownOurSaving, setBuyDownOurSaving] = React.useState([]);

     
    Date.prototype.today = function () { 
    return ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ this.getFullYear();
    }
    Date.prototype.timeNow = function () {
        return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
    }
    const currentDate = new Date();
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    const formattedYesterday = yesterday.toLocaleDateString('en-US', {
      year: 'numeric',
      day: 'numeric',
      month: 'numeric',
      
    });
    const timeToMins = (time) => {
        var b = time.split(/[:\s]/);
        return b[0]%12*60 + +b[1] + (/pm\s*$/i.test(time)? 720 : 0);
    }

    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    const [showMore, setShowMore] = React.useState('false'); 
    const showFun = (val) => {
        setShowMore(val);
    }
    const [showDate, setShowDate] = React.useState('');

    useEffect(() => {
        const token = {
            Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
        };
        // const apiData = {...values, ...slugObj};
        // console.log('valuesss', apiData);
        // setShowLoader(true);
        fetch('https://crm.rateshop.ca/ReactController/mortgageRates', {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(token),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log('result', result);
                    const insuredOneYearFixed = Math.min(...JSON.parse(result.rates.insured_one_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredTwoYearFixed = Math.min(...JSON.parse(result.rates.insured_two_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredThreeYearFixed = Math.min(...JSON.parse(result.rates.insured_three_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredFourYearFixed = Math.min(...JSON.parse(result.rates.insured_four_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredFiveYearFixed = Math.min(...JSON.parse(result.rates.insured_five_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredFiveYearVariable = Math.min(...JSON.parse(result.rates.insured_five_year_variable).filter(function (el) {
                        return el != '';
                      }));
                    setBuyDownLender(JSON.parse(result.buyDownRates.lender));
                    setBuyDownTheirRates(JSON.parse(result.buyDownRates.their_rates));
                    setBuyDownOurRates(JSON.parse(result.buyDownRates.our_rates));
                    setBuyDownOurSaving(JSON.parse(result.buyDownRates.your_savings));

                    setInsuredThreeYearFixed(insuredThreeYearFixed);
                    setInsuredFourYearFixed(insuredFourYearFixed);
                    setInsuredFiveYearFixed(insuredFiveYearFixed)
                    setInsuredFiveYearVariable(insuredFiveYearVariable);
                    setInsuredOneYearVariable(insuredOneYearFixed);
                    setInsuredTwoYearVariable(insuredTwoYearFixed);

                }
            )

               
        if(timeToMins(formatAMPM(currentDate)) > timeToMins('10:00 am')){
            setShowDate(currentDate.today());

        }else if(timeToMins(formatAMPM(currentDate)) > timeToMins('12:00 am') && timeToMins(formatAMPM(currentDate)) < timeToMins('10:00 am')){   
            setShowDate(formattedYesterday);
        }else{
            setShowDate(currentDate.today());
        }

    }, []);
    // console.log('buyDownOurSaving', buyDownOurSaving);
    const [age, setAge] = React.useState('');
    const navigate = useNavigate();

    const routeChange = () =>{ 
        let path = `/apply`; 
        navigate(path);
      }
    // banner select city
    const capitalizeWords = (str) => {
        if (str == '' || str == undefined) {
            return '';
        }
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    const { currentLocation } = params();
    const [city, setCity] = React.useState(currentLocation);
    const [province, setProvince] = React.useState(capitalizeWords(currentLocation.replace(/-/g, " ")));   //{proFiltered.province}
    const [filterprovince, setFilterProvince] = React.useState(currentLocation.replace(/-/g, " "));
    // console.log('province', province);
    const handleChange1 = (event) => {
        setCity(event.target.value);
        setProvince(capitalizeWords(event.target.value.replace(/-/g, " ")));
        setFilterProvince(currentLocation.replace(/-/g, " "));
        setProFiltered(details.find(detail => String(detail.province) === currentLocation));
        navigate('/mortgage-rates/' + event.target.value);
    };
    //   select city
    const [city1, setCity1] = React.useState('');
    const handleChange11 = (event) => {
        setCity1(event.target.value);
    };
    const [showHide, setShowHide] = React.useState('');
    // cs menu 1
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [ourRatesValue, setOurRatesValue] = React.useState(50000);
    const calCulateOurRates = (e) => {
        // console.log('e,', e.target.value);
        setOurRatesValue(e.target.value);
    }
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const fullCalculations = (theirRate, ourRate) => {
        
        const mortgageAmount = ourRatesValue;

        const tmR = (((1+(theirRate/200))**2)**(1/12)-1)*100;
        const omR = (((1+(ourRate/200))**2)**(1/12)-1)*100;

        const tmP = (mortgageAmount*(tmR/100))/(1-(1+(tmR/100))**(-300));
        const omP = (mortgageAmount*(omR/100))/(1-(1+(omR/100))**(-300));
    
        const yourSavings = ( tmP - omP )* 60;

        return '$'+numberWithCommas(yourSavings.toFixed(2));
    }

    // cs menu 2
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const location = useGeoLocation();
    // console.log('location', location);

    useEffect(() => {
      
        setProFiltered(details.find(detail => String(detail.province) === currentLocation));
        // console.log('proF', currentLocation);
        // console.log('filterprovince', proFiltered);
 
 
    }, [filterprovince, province, currentLocation]);
     
    if (insuredThreeYearFixed.length == 0) {
        return <Oval
            height={60}
            width={60}
            color="#FFFFFF"
            wrapperStyle={{}}
            wrapperClass="csLoader"
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#EEEEEE"
            strokeWidth={4}
            strokeWidthSecondary={4}
        />;
    }

if(proFiltered == undefined){
    return <Navigate to="/404" replace />;
}
    

   

    // console.log('proFiltered', proFiltered);
    return (<>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{proFiltered?.meta_title}</title>
                <meta name='description' content={proFiltered?.meta_description} />
                <meta name='keywords' content={proFiltered?.keywords} />
                {(agentSlug && agentSlug.indexOf('-') !== -1)?<meta name="robots" content="noindex,nofollow" />:''}
            </Helmet>
    <a onClick={()=>{setShowHide('d-none')}} className={`close-btn-cs ${showHide}`}><CancelRoundedIcon /></a>
    <NavLink to="/apply" className={showHide}><div className="agent-contact-outers d-flex">
    
    <div className="agent-img">
        <img src={clientImg} alt=""/>
            <span className="status-display"></span>
            </div>
            <div className="msg-info">
                <h5>Sarah P. Mortgage Agent</h5>
                <p><span className="status">Online </span><span className="average-respone-time"> Avg. respone time: 1Hour</span></p></div></div></NavLink>
    <div className="mortage-new">
        {/* interest saving start here */}
        <section className="rateshop-mortagage">
            {/* <img src={savingBanner} alt="" className="saving-bg"/> */}
            <Container > 
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex justify-content-center">
                        <div className="mortgage-inner">
                            <h1>Find the best mortgage rates in {province}, Canada</h1>
                            {/* <p>RateShop.ca Lowest Mortgage Rate offers last update: 25/04/2023 10:04AM EST</p> */}
                            <div className="banner-select">For a property located in  <div className="select-location ">
                                <FormControl fullWidth>
                                    <Select
                                        labelId="selectCity"
                                        id="selectCity"
                                        value={city}
                                        label="City"
                                        onChange={handleChange1}
                                    >
                                        <MenuItem value=''>Select Option</MenuItem>
                                        <MenuItem value='alberta'>Alberta</MenuItem>
                                        <MenuItem value='british-columbia'>British Columbia</MenuItem>
                                        {/* <MenuItem value='manitoba'>Manitoba</MenuItem> */}
                                        <MenuItem value='new-brunswick'>New Brunswick</MenuItem>
                                        {/* <MenuItem value='newfoundland-and-labrador'>Newfoundland and Labrador</MenuItem> */}
                                        {/* <MenuItem value='northwest-territories'>Northwest Territories</MenuItem> */}
                                        <MenuItem value='nova-scotia'>Nova Scotia</MenuItem>
                                        {/* <MenuItem value='nunavut'>Nunavut</MenuItem> */}
                                        <MenuItem value='ontario'>Ontario</MenuItem>
                                        <MenuItem value='prince-edward-island'>Prince Edward Island</MenuItem>
                                        <MenuItem value='quebec'>Quebec</MenuItem>
                                        <MenuItem value='saskatchewan'>Saskatchewan</MenuItem>
                                        {/* <MenuItem value='yukon-territory'>Yukon Territory</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </div>
                            </div>

                            <ul className="d-flex flex-wrap">
                                <li>
                                    <div className="prices-inner">
                                        <p>5-year fixed*</p>
                                        <h3>{insuredFiveYearFixed}%</h3>
                                    </div>
                                </li>
                                <li>
                                    <div className="prices-inner">
                                        <p>5-year variable*</p>
                                        <h3>{insuredFiveYearVariable}%</h3>
                                        <span>(Most Bank's Prime Rate 6.95%)</span>
                                    </div>
                                </li>
                            </ul>
                            <div className="text-center">
                                <a className="mort-apply-now" href="/apply" >Apply Now</a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
        {/* get your best rates */}
        <section className="get-best-rates get-mortgage-rates-cs">
            <Container >
                <Grid container>
                    <Grid item xs={12} className="d-flex align-items-center flex-wrap justify-content-between bes-rates-inner" > 
                        <p className="w-70">RateShop.ca Lowest Mortgage Rate offers last update: {showDate} 10:00 AM EST</p>
                        <img className="get500 w-30" src={get500} alt={proFiltered?.alt1} /> 
                    </Grid>
                </Grid>
            </Container>
        </section>
        {/* Discover the best rates */}

        <Container> 
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link> 
                <Link
                    underline="hover" 
                    to="/mortgage-rates/"
                >
                    Mortgage Rates
                </Link>
                <Typography color="text.primary">{province}</Typography>
            </Breadcrumbs>
        </Container>

        <section className="mortgage-content-img pt-60 pb-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center mob-column">
                    <Grid item lg={6} md={6} xs={12} className="mob-mt-40">
                        <figure>
                            <img className="get500" src={img1} alt={proFiltered?.alt2} />
                        </figure>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} >
                        <h2><span>Lowest</span> Mortgage Rates, Guaranteed!</h2>
                        <p>We're often asked how this works. We work closely with the largest network of lenders in all over Canada. That's a lot of mortgages we fund quickly and efficiently.</p><br></br>
                        <p>Commissions we receive help us heavily discount your mortgage rate, and in the rare situation when we can't match or beat your rate - We respect your time and thank you for checking in with $500 on us!</p>
                        
                        <a className="rs-orange-btn mt-30"  onClick={routeChange}>Compare mortgage Rate Online <NavigateNextIcon /></a>
                    </Grid>
                </Grid>
            </Container>
        </section>
        <section className="discover-best-rates pt-60 pb-60  mortgage-province">
            <Container>
                <Grid container>
                    <Grid item lg={6} md={6} xs={12} className="tab-100" >
                        <h3><span>Dare</span>  to Compare Mortgage Rates</h3>
                        <p>Banks, Credit unions and branchless mortgage lenders compete with your bank's business.
                        </p><br></br>
                        <p>
                            Working with rateshop.ca can get you lower rates than your bank, with the same features like a home equity line of credit or options like pre-payment privileges. </p><br></br>
                        <p>The Only Difference - You Save Thousands!</p>

                        <div className="tbl-bottom-btn d-flex justify-content-start">
                            <a className="rs-orange-btn"  onClick={routeChange}>See which rate you qualify for <NavigateNextIcon /></a>
                        </div>
                        <div className={`read-more-content ${showMore}`}  >
                        
                        <h3>How do we compare our mortgage rates?</h3>
                        <p>Having a well connected network of lenders definitely helps track rates regularly. Above and beyond, we scower the internet and periodically work with our lenders to secure better than average industry rates that result in deeper rate discounts.</p>
                        <p>With RateShop.ca Canadians get unfiltered access to better rate & product offers from more than 65 mortgage lenders accross all provinces. </p>
                        <ul className="mortgage-cs-list">
                            <li>
                                <img src={mLogo1} alt=""/>
                                <p><small>Haggle-Free Better</small></p>
                            </li>
                            <li>
                                <img src={mLogo2} alt=""/>
                                <p><small>Mortgage Choices</small></p>
                            </li>
                            <li>
                                <img src={mLogo3} alt=""/>
                                <p><small>Lender Exceptions</small></p>
                            </li>
                            <li>
                                <img src={mLogo4} alt=""/>
                                <p><small>No Credit Checks</small></p>
                            </li>
                            <li>
                                <img src={mLogo5} alt=""/>
                                <p><small>Compare Your Savings</small></p>
                            </li>
                        </ul>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} className="tab-100 mob-mt-40 info-table">
                        {/* <div className="select-location d-flex justify-content-end">
                            <img src={image1} alt="" />
                            <FormControl fullWidth>
                                <Select
                                    labelId="selectCity1"
                                    id="selectCity1"
                                    value={city1}
                                    label="City1"
                                    onChange={handleChange11}
                                >
                                    <MenuItem value=''>Select Option</MenuItem>
                                    <MenuItem value='alberta'>Alberta</MenuItem>
                                    <MenuItem value='british-columbia'>British Columbia</MenuItem>
                                    <MenuItem value='manitoba'>Manitoba</MenuItem>
                                    <MenuItem value='new-brunswick'>New Brunswick</MenuItem>
                                    <MenuItem value='newfoundland-and-labrador'>Newfoundland and Labrador</MenuItem>
                                    <MenuItem value='northwest-territories'>Northwest Territories</MenuItem>
                                    <MenuItem value='nova-scotia'>Nova Scotia</MenuItem>
                                    <MenuItem value='nunavut'>Nunavut</MenuItem>
                                    <MenuItem value='ontario'>Ontario</MenuItem>
                                    <MenuItem value='prince-edward-island'>Prince Edward Island</MenuItem>
                                    <MenuItem value='quebec'>Quebec</MenuItem>
                                    <MenuItem value='saskatchewan'>Saskatchewan</MenuItem>
                                    <MenuItem value='yukon-territory'>Yukon Territory</MenuItem>
                                </Select>
                            </FormControl>
                        </div> */}
                        <div className="mortgage-input-text">
                            <h3>See Your Savings on the Mortgage Amount</h3>
                            <Input
                            startDecorator="$"
                            onChange={(e) => {calCulateOurRates(e)}}
                            // defaultValue={slidMortgageAmount}
                            value={ourRatesValue}
                            ></Input> 
                        </div>
                         <Slider className="range-slider" defaultValue={100} 
                      min={50000}
                      step={50000}
                      value={ourRatesValue}
                      marks 
                      max={500000} aria-label="Default" name='amount_request' valueLabelDisplay="auto" onChange={(e) => {calCulateOurRates(e)}}  />
                       {/* <Input name='amount_request'
                startDecorator="$" onKeyUp={(e) => {calCulateOurRates(e)}} />  */}
                        <div className="table-responsive cs-nw-tb-des cs-btn-table mt-2 mb-0">
                            <table className= {`see-more-btn  ${showMore}`}>
                                <thead>
                                    <tr>
                                        <th>Lender</th>
                                        <th>5 Yr Fixed Rates</th>
                                        {/* <img src={whitelogo} alt=""/> */}
                                        <th>Your Savings</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src={logo} alt="" />
                                            {/* {buyDownLender[0]} */}
                                        </td>
                                        <td><strong>{insuredFiveYearFixed}%</strong></td>
                                        {/* <td>{fullCalculations(buyDownTheirRates[0], buyDownOurRates[0])}
                                           
                                        </td> */}
                                        <td> <a className="get-rates"  onClick={routeChange}>Get Rates</a></td> 
                                    </tr>
                                    {
                                        buyDownTheirRates[0] != '' ? 


                                        <tr>
                                            <td>
                                            {buyDownLender[0]}
                                                {/* {buyDownLender[0]} */}
                                            </td>
                                            <td> {buyDownTheirRates[0]}% </td>
                                            {/* <td>{fullCalculations(buyDownTheirRates[0], buyDownOurRates[0])}
                                            
                                            </td> */}
                                            <td>{fullCalculations(buyDownTheirRates[0], buyDownOurRates[0])}</td>
                                        </tr> :''
                                    }

                                    {
                                        buyDownTheirRates[1] != '' && buyDownTheirRates[1] != undefined ? 
                                    
                                    <tr>
                                        <td>
                                            {/* <img src={image4} alt="" /> */}
                                            {buyDownLender[1]}
                                        </td>
                                        <td>{buyDownTheirRates[1]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[1], buyDownOurRates[1])}</td>
                                        {/* <td>{buyDownOurSaving[1]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>
                                    :'' }
                                     {
                                       buyDownTheirRates[2] != '' && buyDownTheirRates[2] != undefined ? 
                                    <tr>
                                        <td>
                                            {/* <img src={image5} alt="" /> */}
                                            {buyDownLender[2]}
                                        </td>
                                        <td>{buyDownTheirRates[2]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[2], buyDownOurRates[2])}</td>
                                        {/* <td>{buyDownOurSaving[2]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>:'' }
                                     {
                                        buyDownTheirRates[3] != '' && buyDownTheirRates[3] != undefined ? 
                                    <tr>
                                        <td>
                                            {/* <img src={image6} alt="" /> */}
                                            {buyDownLender[3]}
                                        </td>
                                        <td>{buyDownTheirRates[3]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[3], buyDownOurRates[3])}</td>
                                        {/* <td>{buyDownOurSaving[3]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>:'' }
                                    {
                                       buyDownTheirRates[4] != '' && buyDownTheirRates[4] != undefined ? 
                                    <tr>
                                        <td>
                                            {/* <img src={image7} alt="" /> */}
                                            {buyDownLender[4]}
                                        </td>
                                        <td>{buyDownTheirRates[4]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[4], buyDownOurRates[4])}</td>
                                        {/* <td>{buyDownOurSaving[4]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>:'' }
                                    {
                                        buyDownTheirRates[5] != '' && buyDownTheirRates[5] != undefined ? 
                                    <tr>
                                        <td>
                                            {/* <img src={image3} alt="" /> */}
                                            {buyDownLender[5]}
                                        </td>
                                        <td>{buyDownTheirRates[5]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[5], buyDownOurRates[5])}</td>
                                        {/* <td>{buyDownOurSaving[5]}%</td> */}
                                        {/* <td></td> */}
                                    </tr> :'' }
                                    {
                                        buyDownTheirRates[6] != '' && buyDownTheirRates[6] != undefined ? 
                                    <tr>
                                        <td>
                                            {/* <img src={image3} alt="" /> */}
                                            {buyDownLender[6]}
                                        </td>
                                        <td>{buyDownTheirRates[6]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[6], buyDownOurRates[6])}</td>
                                        {/* <td>{buyDownOurSaving[6]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>:'' }
                                    {
                                        buyDownTheirRates[7] != '' && buyDownTheirRates[7] != undefined ? 
                                    <tr>
                                        <td>
                                            {/* <img src={image3} alt="" /> */}
                                            {buyDownLender[7]}
                                        </td>
                                        <td>{buyDownTheirRates[7]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[7], buyDownOurRates[7])}</td>
                                        {/* <td>{buyDownOurSaving[7]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>:'' }
                                    {
                                        buyDownTheirRates[8] != '' && buyDownTheirRates[8] != undefined ? 
                                    <tr>
                                        <td>
                                            {/* <img src={image3} alt="" /> */}
                                            {buyDownLender[8]}
                                        </td>
                                        <td>{buyDownTheirRates[8]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[8], buyDownOurRates[8])}</td>
                                        {/* <td>{buyDownOurSaving[8]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>:'' }
                                    {
                                        buyDownTheirRates[9] != '' && buyDownTheirRates[9] != undefined ? 
                                    <tr>
                                        <td>
                                            {/* <img src={image3} alt="" /> */}
                                            {buyDownLender[9]}
                                        </td>
                                        <td>{buyDownTheirRates[9]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[9], buyDownOurRates[9])}</td>
                                        {/* <td>{buyDownOurSaving[9]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>:'' }
                                    {
                                        buyDownTheirRates[10] != '' && buyDownTheirRates[10] != undefined ? 
                                    <tr>
                                        <td>
                                            {/* <img src={image3} alt="" /> */}
                                            {buyDownLender[10]}
                                        </td>
                                        <td>{buyDownTheirRates[10]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[10], buyDownOurRates[10])}</td>
                                        {/* <td>{buyDownOurSaving[10]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>:'' }
                                    {
                                        buyDownTheirRates[11] != '' && buyDownTheirRates[11] != undefined ? 
                                    <tr>
                                        <td>
                                            {/* <img src={image3} alt="" /> */}
                                            {buyDownLender[11]}
                                        </td>
                                        <td>{buyDownTheirRates[11]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[11], buyDownOurRates[11])}</td>
                                        {/* <td>{buyDownOurSaving[11]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>:'' }
                                    {
                                        buyDownTheirRates[12] != '' && buyDownTheirRates[12] != undefined ? 
                                    <tr>
                                        <td>
                                            {/* <img src={image3} alt="" /> */}
                                            {buyDownLender[12]}
                                        </td>
                                        <td>{buyDownTheirRates[12]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[12], buyDownOurRates[12])}</td>
                                        {/* <td>{buyDownOurSaving[12]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>:'' }
                                    {
                                        buyDownTheirRates[13] != '' && buyDownTheirRates[13] != undefined? 
                                    <tr>
                                        <td>
                                            {/* <img src={image3} alt="" /> */}
                                            {buyDownLender[13]}
                                        </td>
                                        <td>{buyDownTheirRates[13]}%</td>
                                        <td>{fullCalculations(buyDownTheirRates[13], buyDownOurRates[13])}</td>
                                        {/* <td>{buyDownOurSaving[13]}%</td> */}
                                        {/* <td></td> */}
                                    </tr>:'' }
                                </tbody>
                            </table>
                        </div>
                        <div className= {`see-more ${showMore}`}  >
                            <a className="see-more-btn" onClick={ () => { setShowMore('active')}}>See more</a>
                            <a className="see-less-btn" onClick={ () => { setShowMore('inactive')}}>See less</a>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
      {/* Mortgage Rates Section Start */}
        <div className="pt-70  mortgage-province" >
      <MortgageRate />
      </div>
      {/* Mortgage Rates Section End */}
        <section className="mortgage-content-img info-table  mortgage-province  ptt-0">
            <Container>
                <Grid container className="align-items-center mob-column">
                    <Grid item lg={6} md={6} xs={12} className="mob-mt-40">
                            <img className="get500" src={img2} alt={proFiltered?.alt3}  />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} >
                        <h2><span> RateShop.ca</span> Mortgage Brokers in {province}</h2>
                        <Markup markup={proFiltered?.mortgage_broker} />
                    </Grid>
                </Grid>
            </Container>
        </section> 
        <Calculator />
        <section className="get-best-rates bt-with-icon  ">
            <Container >
                <Grid container>
                    <Grid item xs={12} className="w-60 d-flex align-items-center flex-wrap justify-content-between bes-rates-inner" >
                        {/* <p>RateShop.ca Lowest Mortgage Rate offers last update:  EST</p>
                        <a className="rs-orange-btn">
                            Start a Free Rate Quote <NavigateNextIcon />
                        </a> */}
                        <p className="w-60">Get Pre-Approved with a mortgage rate hold for 180 days*</p>
                        <div className="icon-button w-40"> 
                            <img src={homeIcon} alt={proFiltered?.alt4} />
                            <a className="rs-orange-btn"  onClick={routeChange}>See which rate you qualify for <NavigateNextIcon /></a>
                        </div> 
                        {/* <a>See all rates</a> */}
                    </Grid>
                </Grid>
            </Container>
        </section>

        {/* Mortgage rate  */}<section className="mortgage-content-img info-table cs-table-font pt-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center">
                    <Grid item lg={7} md={6} xs={12}>
                        <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Fixed Rates</th>
                                        <th>Mortgage Expert Insights</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="fixed-rate">
                                        <td>1 Year <span>Fixed Rate</span></td>
                                        {/* <td>{insuredOneYearFixed}</td> */}
                                        <td>Great solution for a short-term mortgage needs, renewals can be competitive but rates can go up at maturity without notice. Perfect for new builds to sell after a year or refinance for equity.</td>
                                    </tr>
                                    <tr className="fixed-rate">
                                        <td>2 Years <span>Fixed Rate</span></td>
                                        {/* <td>{insuredTwoYearFixed}</td> */}
                                        <td>More flexible, a longer duration to support a family need for a couple of years or planning an exit from an existing mortgage without penalties.</td>
                                    </tr>
                                    <tr className="fixed-rate">
                                        <td>3 Years <span>Variable Rate</span></td>
                                        {/* <td>{insuredThreeYearFixed}</td> */}
                                        <td>3 Year terms can sometimes deliver the best savings, but are typically suggested in a low rate environment, consider a variable too since upon maturity you may get stuck with a higher renewal.</td>
                                    </tr>
                                    <tr className="fixed-rate">
                                        <td>4 Years<span>Fixed Rate</span></td>
                                        {/* <td>{insuredFourYearFixed}</td> */}
                                        <td>Banks use this to gain your business, but if you are saving atleast 20-30bps, definitely consider a 4 year term mortgage, compare your savings on a 5 year mortgage term.</td>
                                    </tr>
                                    <tr className="fixed-rate">
                                        <td>5 Years <span>Fixed Rate</span></td>
                                        {/* <td>{insuredFiveYearFixed}</td> */}
                                        <td>On average, households will upgrade or alter their mortgage about every 5 years, avoiding penalties upon maturity and best rate savings.</td>
                                    </tr>
                                    <tr className="fixed-rate">
                                        <td>5 Years <span>Variable Rate</span></td>
                                        {/* <td>{insuredFiveYearVariable}</td> */}
                                        <td>Usually recommended in lower rate environments, beat the bank on mortgage penalties and optimize your savings compared to a fixed mortgage offer for the same term.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Grid>
                    <Grid item lg={5} md={6} xs={12} >
                        <h2><span>Choosing</span>  the right mortgage term</h2>
                        <p>Don't lock in just because your neighbour did! Mortgage terms vary from 6 months all the way up to 10 years and you can choose based on your family financial needs.</p>
                        <br></br><p>Are you selling soon? Or maybe you want to invest in 2 years. Maybe you want some flexibility, the choice is yours but make it an educated one when you talk to our commission-free mortgage advisors to help you decide on what offers the best mortgage rate and highest savings.</p>
                    </Grid>
                </Grid>
            </Container>
        </section>

        <section className="mortgage-content-img pt-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center"> 
                <Grid item  xs={12}  >
                        <h2><span>Where</span> is the Mortgage Market heading?</h2>
                        <p>History can teach us a lot, check out Canada's mortgage rates history over the past 48 years. Bank of Canada tracks conventional mortgage rates for 3 year and 5 year terms. We can help with understanding the pros & cons to a fixed mortgage rate vs. a variable mortgage rate. </p>
                    </Grid>
                    <Grid className="m-r-graph mt-30" item lg={12} md={12} xs={12}>
                    <h2 className="text-center">Canada's Historical Mortgage Rates</h2>
                        <Line options={options} data={data} />
                        <h4>Source: Bank of Canada Prime Rate History</h4>
                    </Grid> 
                </Grid>
            </Container>
        </section> 
        {/*  */}
        {/* <section className="mortgage-content-img ">
            <Container>
                <Grid container className="align-items-center">
                    <Grid className="m-r-graph" item lg={12} md={12} xs={12}>
                    <h2 className="text-center"><span>Canada's</span>  Historical Mortgages Rates</h2>
                        <Line options={options} data={data} />
                        <h4>Source: Bank of Canada Prime Rate History</h4>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} >
                        <h2><span>Where</span> is the Mortgage Market heading?</h2>
                        <p>History can teach us a lot, check out Canada's mortgage rates history over the past 48 years.</p><br></br>
                        <p>Bank of Canada tracks conventional mortgage rates for 3 year and 5 year terms. </p><br></br>
                        <p>We can help with understanding the pros & cons to a fixed mortgage rate vs. a variable mortgage rate. </p>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} className="mob-mt-40">
                        <h2><span>Where</span> is the Mortgage Market heading?</h2>
                        <p>History can teach us a lot, check out Canada's mortgage rates history over the past 48 years.</p><br></br>
                        <p>Bank of Canada tracks conventional mortgage rates for 3 year and 5 year terms. </p><br></br>
                        <p>We can help with understanding the pros & cons to a fixed mortgage rate vs. a variable mortgage rate. </p>
                    </Grid>
                </Grid>
            </Container>
        </section> */}
         <section className="mortgage-content-img pt-60 pb-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center">
                    <Grid item lg={5} md={5} xs={12} >
                        <figure>
                            <img className="get500" src={img10} alt={proFiltered?.alt5} />
                        </figure>
                    </Grid> 
                    <Grid item lg={7} md={7} xs={12} className="d-flex flex-column justify-content-center align-items-center column-direction">
                        <h2 className="text-center">Get Upto <span>$14000 </span> in cashback when you get approved with our Lowest Rates!</h2> 
                        <ul className=" d-flex flex-wrap justify-content-center cs-listing">
                        <li>
                                <figure>
                                    <img src={purchaseIcon} alt={proFiltered?.alt6}/>
                                </figure> 
                                Purchase</li>
                            <li>
                                <figure>
                                    <img src={refinanceIcon} alt={proFiltered?.alt7} />
                                </figure>Refinance</li>
                            <li>
                                <figure>
                                    <img src={switchIcon} alt={proFiltered?.alt8} />
                                </figure>Switch</li>
                        </ul>
                            <a className="rs-orange-btn mt-30"  onClick={routeChange}>Get a Free Quote <NavigateNextIcon /></a>
                    </Grid>
                </Grid>
            </Container>
        </section>
        <section className="get-best-rates bt-with-icon  ">
            <Container >
                <Grid container>
                    <Grid item xs={12} className="w-60 d-flex align-items-center flex-wrap justify-content-between bes-rates-inner" >
                        {/* <p>RateShop.ca Lowest Mortgage Rate offers last update:  EST</p>
                        <a className="rs-orange-btn">
                            Start a Free Rate Quote <NavigateNextIcon />
                        </a> */}
                        <p className="w-60">Get Your Lowest Renewal Offer from Us</p>
                        <div className="icon-button w-40">
                            <img src={bellIcon} alt="Lowest Renewal Offer" />
                            <a className="rs-orange-btn"  onClick={routeChange}>Get your rate <NavigateNextIcon /></a>
                        </div> 
                        {/* <a>See all rates</a> */}
                    </Grid>
                </Grid>
            </Container>
        </section>
        {/*  */}
        {/* <section className="mortgage-content-img">
            <Container>
                <Grid container className="align-items-center">
                    <Grid item lg={5} md={5} xs={12}>
                        <figure>
                            <img className="get500" src={image16} alt="" />
                        </figure>
                    </Grid>
                    <Grid item lg={7} md={7} xs={12} className="mob-mt-40">
                        <h2><span>RateShop.ca</span> Mortgage Brokers</h2>
                        <p>Our agents are licensed to trade in mortgages in {capitalizeWords(proFiltered?.city.replace(/-/g, " "))}, {province}. {capitalizeWords(proFiltered?.city.replace(/-/g, " "))} has seen a significant growth in property demand and as the demand continues to grow.  They specialize in finding the lowest interest rates and best mortgage products to suit individual needs, regardless of the size and type of property being purchased. With access to a wide range of lenders, mortgage brokers in {capitalizeWords(proFiltered?.city.replace(/-/g, " "))} provide personalized service to their clients by analyzing their financial situation and recommending the best mortgage product for their particular situation. Whether you are looking for a traditional mortgage or a specialized product, our mortgage brokers in {capitalizeWords(proFiltered?.city.replace(/-/g, " "))} can help you find the perfect mortgage for your unique needs.</p>
                    </Grid>
                </Grid>
            </Container>
        </section> */}
        {/* Mortgage calculator */}
        
        {/* <Calculator />
        <section className="mortgage-calculator-outer d-none">
            <Container>
                <div className="mortgage-content-img text-center pt-0 pb-0">
                    <h2>Calculator</h2>
                </div>

                <Grid container>
                    <Grid item md={4} xs={12}>
                        <div className="mortgage-calculator">
                            <div className="calculator-wrap">
                                <figure>
                                    <img src={image9} alt="" />
                                </figure>
                                <h2>
                                    <span>Mortgage</span> Payment Calculator
                                </h2>
                            </div>
                            <div className="title">
                                <p>Mortgage Calculator</p>
                            </div>

                        </div>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <div className="mortgage-calculator">
                            <div className="calculator-wrap">
                                <figure>
                                    <img src={image10} alt="" />
                                </figure>
                                <h2>
                                    <span>Mortgage</span> Payment Calculator
                                </h2>
                            </div>
                            <div className="title">
                                <p>Mortgage Calculator</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <div className="mortgage-calculator">
                            <div className="calculator-wrap">
                                <figure>
                                    <img src={image11} alt="" />
                                </figure>
                                <h2>
                                    <span>Mortgage</span> Payment Calculator
                                </h2>
                            </div>
                            <div className="title">
                                <p>Mortgage Calculator</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={12} className="calculator-text d-none">
                        <p>The loan-to-value (LTV) ratio is a measure comparing your mortgage amount with the appraised value of the property. The higher your down payment, the lower your LTV ratio.</p>
                        <p>*Applicable only with an accepted offer to purchase or renewal (not applicable for pre-qualifications or refinances)</p>
                        <a className="rs-orange-btn">See which rate you qualify for <NavigateNextIcon /></a>
                    </Grid>
                </Grid>
            </Container>
        </section> */}
        {/* top bank rates */}

        <section className="mortgage-content-img pt-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center">
                    <Grid item lg={7} md={7} xs={12} >
                        <h2><span>About</span> {province}'s Mortgage Market </h2> 
                        <Markup markup={proFiltered?.about} />
                    </Grid>
                    <Grid item lg={5} md={5} xs={12}>
                        <figure>
                            <img className="get500" src={img3} alt={proFiltered?.alt9} />
                        </figure>
                    </Grid>
                </Grid>
            </Container>
        </section> 

        <section className="mortgage-content-img pt-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center mob-column">
                    <Grid item lg={5} md={5} xs={12}  className="mob-mt-40">
                        <figure>
                            <img className="get500" src={img4} alt={proFiltered?.alt10} />
                        </figure>
                    </Grid>
                    <Grid item lg={7} md={7} xs={12}> 
                        <a className="rs-orange-btn"  onClick={routeChange}>{province}'s Residential & Commercial Real Estate Landscape <NavigateNextIcon /></a>
                        <h2 className="mt-30"><span>{province}'s</span>&nbsp;Residential & Commercial Real Estate Landscape  </h2>
                        <Markup markup={proFiltered?.landscape} /> 
                    </Grid>
                </Grid>
            </Container>
        </section>
        {/* Morttgage content img */}
        {/* <section className="mortgage-content-img">
            <Container className="text-center">  <h2><span>Ave</span> rage bank Posted Mortgage Rate History.</h2> </Container>
            <Container>
                <Grid container className="align-items-center mob-column">
                    <Grid item md={6} xs={12} className="mob-mt-40">
                        <figure>
                            <img src={image13} alt="" />
                        </figure>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h2><span>Deciding</span> on the Best Mortgage?</h2>
                        <div className="lock-into-mortgage">
                            <p>Getting the lowest mortgage rates is important to reduce the overall cost of borrowing,
                                and we help our clients every day do just that by comparing dozens of lenders.
                                Our Discount Cashback policy has saved our borrowers $4300* on average.</p><br></br>
                            <p>Working with RateShop.ca is a smart mortgage rate shopping experience because our team
                                goes above an beyond to look out for factors that could impact your mortgage rate!</p>

                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section> */}

        {/* Morttgage content img */}
        <section className="mortgage-content-img pt-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center ">
                    <Grid item md={6} xs={12}>

                        <h2><span>Property </span>  Use in { province }</h2>
                        <div className="lock-into-mortgage">
                        <Markup markup={proFiltered?.property_use} />
                            <a className="rs-orange-btn mt-30"  onClick={routeChange}>See which rates you qualify for <NavigateNextIcon /></a>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12} className="mob-mt-40">
                        <figure>
                            <img src={img5} alt={proFiltered?.alt11}/>
                        </figure>
                    </Grid>
                </Grid>
            </Container>
        </section>
        {/* Morttgage content img */}
        <section className="mortgage-content-img pt-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center mob-column">
                    <Grid item md={6} xs={12} className="mob-mt-40">
                        <figure>
                            <img src={img6} alt={proFiltered?.alt12} />
                        </figure>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h2><span>Best</span>   Mortgage Banks, Lenders in   {province}
                        </h2>
                        <div className="lock-into-mortgage">
                        <Markup markup={proFiltered?.mortgage_lender} />
                            <a className="rs-orange-btn mt-30"  onClick={routeChange}>Connect with an Advisor <NavigateNextIcon /></a>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
        {/* Morttgage content img */}
        <section className="mortgage-content-img pt-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center  "> 
                    <Grid item md={6} xs={12}>
                        <h2><span>Mortgage</span>  Programs Offered in {province} Cities Near You   </h2>
                        <div className="lock-into-mortgage">
                           
                        <Markup markup={proFiltered?.mortgage_program_offered} />
                            <a className="rs-orange-btn mt-30"  onClick={routeChange}>Connect with an Advisor <NavigateNextIcon /></a>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12} className="mob-mt-40">
                        <figure>
                            <img src={img7} alt={proFiltered?.alt13} />
                        </figure>
                    </Grid>
                </Grid>
            </Container>
        </section>
        {/* Morttgage content img */}
        <section className="mortgage-content-img pt-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center mob-column">
                    <Grid item md={6} xs={12} className="mob-mt-40">
                        <figure>
                            <img src={img8} alt={proFiltered?.alt14} />
                        </figure>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h2><span>Purchase, Refinance </span>   & Renewal Closing Costs in {province}</h2>
                        <div className="lock-into-mortgage">
                          
                        <Markup markup={proFiltered?.closing_cost} />
                            <a className="rs-orange-btn mt-30"  onClick={routeChange}>See which rate you qualify for <NavigateNextIcon /></a>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
        <section className="mortagage-loc-text  mortgage-province">
           <Container> <Markup markup={proFiltered?.about_city_2} /></Container>    
        </section>
        {/* Morttgage content img */}
        <section className="mortgage-content-img pt-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center ">
                    <Grid item  xs={12}>
                        <h2><span>Home</span>   Buyer Incentives in {capitalizeWords(proFiltered?.province.replace(/-/g, " "))}</h2>
                        <div className="lock-into-mortgage">
                         <Markup markup={proFiltered?.buyer_incentive} />
                        </div>
                    </Grid> 
                </Grid>
            </Container>
        </section>






        {/* <section className="mortgage-content-img pt-60 pb-60">
            <Container>
                <Grid container className="align-items-start justify-content-center">
                    <Grid item   xs={12}>
                        <h2 className="text-center">FAQ’s</h2>
                        <div className="faq-outer">
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <Typography>Should I choose the lender with the lowest rate?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        The lowest rate is not always the best option for everyone. Depending on your short and long-term goals for owning your home, it may be wiser to choose the mortgage solution that works best for you. The solution that is best for you may or may not have the lowest rate option. As the mortgage rate is priced based on the risk that the borrower represents for the lender, it may be best to review the restrictions attached to the rate.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                    <Typography>What are mortgage prepayment options?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                                        sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                        sit amet blandit leo lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                    <Typography>How do I compare mortgage rates in Canada?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                                        sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                        sit amet blandit leo lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion></div>
                    </Grid>

                </Grid>
            </Container>
        </section> */}
        
        <section className="mortgage-content-img cs-mortgage-ne-content pt-60  mortgage-province">
            <Container>
                <Grid container className="align-items-center ">
                    <Grid item  xs={12}>
                        
                        <div className="lock-into-mortgage">
                            <h3>How to improve your finances with the help of a mortgage?</h3>
                            <ul>
                                <li>Consolidate Debt - helps lower your overall interest rate and reduce your monthly payments.</li>
                                <li>Tax Benefits</li>
                                <li>Invest in real estate</li>
                                <li>Use a mortgage to improve your home to increase the value and potentially earn a higher return on investment if you sell it later.</li>
                            </ul>
                            <h3>
                                What kind of mortgages are offered in Canada?
                            </h3>
                            <ul>
                            <li>  Open Mortgage </li>
                            <li>Closed Mortgage </li>
                            <li>HELOC </li>
                            <li>Reverse Mortgage</li>
                            <li>Conventional Mortgage</li>
                            <li>Convertible Mortgage</li>

                            </ul>
                            <h3>ARM (Adjustable-Rate Mortgage) or VRM (Variable Rate Mortgage)?</h3>
                            <p>Variable mortgage your mortgage payment amount always remains the same it does not change even if the prime lending rate changes. While adjustable rate mortgage, the amount of your payment changes depending on the prime lending rate.</p>
                            <h3>What mortgage rates are available?</h3>
                            <ul>
                                <li>Variable Rates</li>
                                <li>Fixed Rates</li>
                                <li>Adjustable Rates</li>

                            </ul>
                            <h3>What are today's Best Mortgage rates?</h3>
                            <ul>
                                <li>{insuredFiveYearFixed}% - 5 year fixed </li>
                                <li>{insuredFiveYearVariable}% - 5 year variable </li>
                            </ul>
                            <p>You can work with a Rateshop.ca Mortgage Advisors to help you compare options from multiple lenders and find the best mortgage for your needs. It's important to consider not only the interest rate, but also other factors such as the term of the loan, any fees or penalties, the lender's reputation and customer service.</p>
                            <h3>What is a downpayment or Equity?</h3>
                            <p>Downpayment is a payment made by the purchaser when buying a property which means the purchaser's initial investment in purchasing a property. While Equity is the value of your house minus the mortgage amount.</p>
                            <h3>What's included in closing costs?</h3>
                            <ul>
                                <li>Closing cost is typically 1.5% of your purchase price. This includes but are not limited to
                                    <ul>
                                        <li>Land Transfer Tax</li>
                                        <li>Lawyer and Legal Fees</li>
                                        <li>Title Insurance</li>
                                        <li>Mortgage Broker Fee</li>
                                        <li>Property Insurance</li>

                                    </ul>

                                </li>
                            </ul>
                            <h3>What is Mortgage Insurance?</h3>
<p>Mortgage insurance is an insurance that protects the mortgage lender or title holder if the borrower fails to make payments, dies, or is otherwise unable to meet the mortgage's terms and conditions.</p>  
<h3>Securing the Best Mortgage Rate</h3>
<ul>
    <li>Improve your credit score.</li>
    <li>Stabilize your income.</li>
    <li>Increase your down payment.</li>
</ul> 
<h3>Getting a Mortgage Pre-Approval</h3>
<p>Getting a mortgage pre-approval is an important step in the home buying process. There are several reasons to get pre-approved for a mortgage:</p>
<ul>
    <li>Helps you determine the maximum amount of money you can borrow from the lender</li>
    <li>May allow you to lock in an interest rate when you get pre-approved</li>
    <li>Lender may identify potential issues with your credit or income that could affect your ability to obtain a mortgage, allowing you to address these issues before making an offer on a property.</li>
</ul> 
<h3>Working with a Mortgage Advisor</h3>
<p>When working with a mortgage advisor, it's important to choose someone who is experienced and reputable. Look for an advisor who is licensed, has a good reputation, and has experience working with clients in similar financial situations to yours. With Rateshop.ca Mortgages, we can assist you with the ff: </p>
<ul>
    <li>Help You Secure the Best Deal</li>
    <li>Help you to build wealth through real estate investing</li>
    <li>Gives Expert Advice</li>
    <li>Save time and effort on your end</li>
    <li>On-going support</li>
</ul> 
<h3>What is a Mortgage Rate hold?</h3>
<ul>
    <li>Rate hold is the length of time that the lender will lock in your quoted rate. 
    </li>
</ul>
<h3>Credit checks while Mortgage Rate Shopping</h3>
<ul>
    <li>Shopping for mortgage quotes will affect your credit score as most lenders pull credit reports when offering a rate quote, however here at Rateshop we only pull your credit score once we have confirmed which lender to submit to.</li>
</ul> 
<h3>Mortgage Features to look out for</h3>
<p>When evaluating different mortgage options, consider which features are most important to you and your financial goals. A mortgage professional from Rateshop.ca Mortgages can help you navigate the different options and find the right mortgage for your needs. Here are mortgage features to look out for: </p>
<ul>
    <li>Interest rate</li>
    <li>Fees</li>
    <li>Repayments</li>
    <li>Amortization period</li>
    <li>Prepayment options</li>
    <li>Payment frequency</li>
    <li>Term length</li>
</ul>

<h3>Are fixed Rates or variable rates better?</h3>
<p>Whether Fix Rate or Variable rate depends on your needs and financial stability. For Fix rates, your mortgage interest rate will stay the same the entire duration of your mortgage term which means your payments will also remain the same and you’re following the amortization schedule. While on the other hand Variable Rates goes up and down based on current market conditions in which payments for payment may change frequently.</p>

<h3>Amortization</h3>
<p>Amortization is the length of time it takes to pay off the mortgage in full, typically between 25 or 30 years.</p>

<h3>PrePayment Privileges </h3>
<p>Prepayment privilege refers to a customer’s ability to pay off part of the mortgage before it matures without incurring any penalties.</p>

<h3>HELOC</h3>
<p>A home equity line of credit (HELOC) is a type of secured credit that uses your home as collateral. HELOCs are revolving credit meaning you can borrow money up to a maximum credit limit.</p>
<p>With a HELOC, homeowners can borrow against the equity in their home, up to a predetermined credit limit. The interest rate on a HELOC is typically lower than the interest rate on other types of loans, such as credit cards or personal loans, because the loan is secured by the equity in the home.</p>

<h3>Portable or Transferable Mortgages</h3>
<p>Portable mortgage allows you to transfer your existing mortgage on your current home to the new property and retain the same terms of the original mortgage</p>

 
<h3>Standard Vs. Collateral Mortgages</h3>
<p>The main difference between a standard mortgage and a collateral mortgage is how the loan is secured.</p> 

<p> A standard mortgage is a type of loan where the property being purchased is used as collateral to secure the loan. The loan is registered with the government, and the lender has a claim on the property if the borrower defaults on the loan. </p>

<p>A collateral mortgage is a type of loan where the lender uses the property being purchased, plus any additional property owned by the borrower, as collateral to secure the loan. The loan is registered with the government, and the lender has a claim on all of the properties used as collateral if the borrower defaults on the loan. </p>

<p>The main advantage of a collateral mortgage is that it allows the borrower to access additional funds using their existing properties as collateral, without having to go through the mortgage application process again. However, collateral mortgages can be more difficult to transfer to a new lender, and they may have higher penalties if the borrower wants to break the mortgage early.</p>

<h3>Steps in a Mortgage Closing</h3>
<ul>
    <li>Pre-Qualification - initial assessment of a borrower's financial situation and creditworthiness to determine how much money they may be eligible to borrow. </li>
    <li>Approval - If the borrower meets the lender's lending criteria and the property is eligible for financing, the lender will provide a mortgage offer that outlines the terms of the loan.</li>
    <li>Conditions & Appraisals- completing conditions and the lender orders an appraisal of the property to determine its value and ensure it is worth the purchase price/property value.</li>
    <li>Solicitor Instructions - solicitor will receive instructions from the lender</li>
    <li>Signing - The buyer reviews and signs the loan documents, which include the mortgage agreement and other legal documents related to the loan.</li>
    <li>Funding - Once all the documents are signed and all conditions are complete, the mortgage loan is funded and the purchase of the property is complete.</li>
</ul>

<h3>Qualification Criteria</h3>
<p>Qualification criteria for mortgages can vary depending on the lender and the type of mortgage being applied for.</p>
<ul>
    <li>
        <h3>Income </h3>
        <p>Income is an important qualification criterion when applying for a mortgage, as it is an indicator of your ability to repay the loan. Lenders typically require borrowers to have a stable and sufficient income to support their mortgage payments over the long term.</p>

        <p>When evaluating your income, lenders will consider various factors, such as the amount and stability of your income, your employment history, and the debt-to-income ratio. In Canada we call this the gross and total debt service ratio. What monthly payment you are permitted to manage compared to your monthly household income, along with your heating costs called GDS and other then along with other debt expenses called TDS capped to 39% and 44% respectively.</p>

        <p>Each lender has a different acceptance to the kind of income. Salary and Hourly wages paid regularly are more preferred by the big lenders, and that can mean little flexibility to self employed or commissioned and contract employees. Banks look at income as either documented or self declared. Those with a documented proven income can qualify for better mortgage rates. Working with a mortgage broker can help identify lenders that are flexible towards the self declared, all while getting best mortgage rates for the documented income applicants.</p>
    </li>
    <li> 
        <h3>Credit </h3>
        <p>A borrower's credit score is a reflection of their credit history, including their repayment history on previous loans, the amount of debt they currently have, and how long they've had credit. A higher credit score generally indicates that a borrower has a good credit history and is more likely to be able to repay their mortgage on time and in full. The credit score rating is offered by Equifax and Transunion in Canada as Credit Reporting Bureaus. Though similarities exist in their independent Scoring Algorithm, the actual scores tend to vary between the two. The credit report in Canada compiles your credit accounts like credit cards, line of credits, vehicle loans, mortgages and some phone or utility payments history, reported to your personal credit file that eventually creates a score of financial worthiness when being lent to. </p>

        <p>A credit report will also contain any Fraud & Identity Alerts, collection accounts and late or missed payments also known as derogs. So keep on top of your credit report for free, and track your credit score regularly.</p>

        <p>When applying for a mortgage, lenders will typically check a borrower's credit score and credit report to assess their creditworthiness. If a borrower has a high credit score and a clean credit history, they will be offered more favorable mortgage terms, such as a lower interest rate or a higher loan amount.</p>

    </li>
    <li>

        <h3>Stress Test</h3>
        <p>The Canadian mortgage stress test first introduced on January 1, 2018, is a financial assessment that lenders use to evaluate whether a borrower can afford to make mortgage payments should the interest rates rise or if their financial situation changes.</p>

        <p>In Canada, the mortgage stress test is a requirement for all borrowers applying for a mortgage, and it applies to both insured and uninsured mortgages. The stress test requires borrowers to qualify for a mortgage at a higher interest rate than the one they will actually be paying, usually two percentage points higher than the contract rate or the Bank of Canada's five-year benchmark rate, whichever is higher.</p>

        <p>The idea behind the stress test is to ensure that borrowers can handle a potential rise in interest rates and maintain their mortgage payments over the long term, without becoming financially stressed or defaulting on their loan.</p>


    </li>
    <li>
        <h3>Pitfalls of a bad mortgage</h3>
        <ul>
            <li>Hidden Fees </li>
            <li>High Interest Mortgage </li>
            <li>Failure to get the discounted rate and special features</li>
            <li>Pre-Termination Penalties / Prepayment Penalties</li>
        </ul>
        <p>It's important to carefully research and compare different mortgage options before making a decision. Be sure to read the fine print and ask questions about any fees or penalties associated with the mortgage. With this, Rateshop.ca Mortgage Advisors are here to help you secure the best mortgage product and rates.</p>
    </li>
    <li>
        <h3>Hefty Penalties</h3>
        <p>In Canada, mortgage lenders often include penalties to discourage borrowers from breaking their mortgage contract before the end of the term. These penalties can be significant and can add up to thousands of dollars. Here are penalties you might encounter when you have a mortgage: </p>
        <ul>
            <li>Prepayment Penalty</li>
            <li>Discharge Fee</li> 
        </ul>
    </li>
    <li>
        <h3>Refinance Restrictions</h3>
        <p>In Canada, there are some restrictions on refinancing your mortgage, which are designed to protect lenders and borrowers. Rateshop.ca Mortgage Advisors can help you evaluate your options and determine if refinancing is right for you. Here are some of the main restrictions you should be aware of:</p>
        <ul>
            <li>
            Maximum LTV (Loan-to-Value) is 80%, which means you cannot borrow more than 80% of your home's appraised value.</li>
            <li>Minimum Credit score for refinancing is 620.</li>
            <li>Lender will ask for income verification again if you can afford the new repayment</li>
            <li>If you're refinancing before the end of your current mortgage term, you may be subject to penalties, such as prepayment penalties or mortgage discharge fees. These fees can be significant and may offset any potential savings from refinancing.</li>
            <li>Appraisal is required and clients have to pay for it</li>
            <li>Limited customer service options</li>
        </ul>
    </li>
</ul>


                        </div>
                    </Grid>
                    {/* <Grid item md={6} xs={12} className="mob-mt-40">
                        <figure>
                            <img src={image16} alt="" />
                        </figure>
                    </Grid> */}
                </Grid>
            </Container>
        </section> 
        <PartnerLogo2 />

        {/* partner logo Start*/}
        {/* <Partner /> */}
        {/* partner logo End*/}
        </div>
    </>
    )
}

export default RateshopMortgage